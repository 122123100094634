import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Action,
  buildReleaseVersion,
  Dag,
  DeploymentUnitStatus,
  generateURNByKind,
  GetProvisioningStatusResponse,
  ReleaseEntity,
  SystemType,
  TaskAction,
  TerminationMode,
} from '@agilelab/plugin-wb-builder-common';
import {
  customAlertApiRef,
  useSelectorsContext,
} from '@agilelab/plugin-wb-platform';
import { Environment } from '@agilelab/plugin-wb-platform-common';
import useAsyncFn, { AsyncState } from 'react-use/lib/useAsyncFn';
import { useApi } from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';
import { panelCatalogApiRef } from '../../../api';
import { getComponentsFromDescriptor, mapToDeploys } from '../utils';
import {
  Component,
  ComponentWithStatus,
  Deploy,
  ReleaseDetailFilters,
} from '../types';
import { GET_DATA_PRODUCT_INSTANCE_ID } from '@agilelab/plugin-wb-marketplace-common';
import yaml from 'yaml';
import { mapToTests } from '../../EditorPage/utils';
import { Test } from '../../ControlPanel';
import { gql, useQuery } from '@apollo/client';

export type ReleaseDetailPageContextProps = {
  queryParamVersion: string | null;
  queryParamEnvironment: string | null;

  entity: Entity;
  systemType: SystemType;
  projectName: string;
  environment: Environment;
  release: ReleaseEntity;
  fetchRelease: () => Promise<void>;

  fetchReleaseDescriptor: () => Promise<string | null>;

  fetchDeploys: (options?: { includeSnapshots?: boolean }) => Promise<Deploy[]>;
  deploysState: AsyncState<Deploy[]>;

  components: ComponentWithStatus[];

  filters: ReleaseDetailFilters;
  changeFilters: <K extends keyof ReleaseDetailFilters>(
    key: K,
    value: ReleaseDetailFilters[K],
  ) => void;
  resetFilters: () => void;

  latestProvisioningExpanded: boolean;
  setLatestProvisioningExpanded: React.Dispatch<React.SetStateAction<boolean>>;

  deployAll: () => Promise<void>;
  undeployAll: () => Promise<void>;

  terminate: (mode: TerminationMode) => Promise<void>;
  terminateMode?: TerminationMode;
  setTerminateMode: React.Dispatch<
    React.SetStateAction<TerminationMode | undefined>
  >;

  isDeployingAll: boolean;
  isUndeployingAll: boolean;
  setIsDeployingAll: React.Dispatch<React.SetStateAction<boolean>>;
  setIsUndeployingAll: React.Dispatch<React.SetStateAction<boolean>>;

  selectedStep: Dag | undefined;
  setSelectedStep: React.Dispatch<React.SetStateAction<Dag | undefined>>;

  selectedDeploy: Deploy | undefined;
  setSelectedDeploy: React.Dispatch<React.SetStateAction<Deploy | undefined>>;

  isLogsDrawerOpen: boolean;
  setIsLogsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;

  isValidationTestDrawerOpen: boolean;
  setIsValidationTestDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  validationTestResultDetailContent: JSX.Element | undefined;
  setValidationTestResultDetailContent: React.Dispatch<
    React.SetStateAction<JSX.Element | undefined>
  >;

  isDescriptorDrawerOpen: boolean;
  setIsDescriptorDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;

  fetchDeploymentUnitStatus: () => Promise<GetProvisioningStatusResponse | null>;
  deploymentUnitStatusState: AsyncState<GetProvisioningStatusResponse | null>;
  // this holds the current descriptor, do not use other variables to access it
  currentDescriptor: string;

  testTaskId: string | undefined;
  setTestTaskId: React.Dispatch<React.SetStateAction<string | undefined>>;

  fetchTests: () => Promise<{ test: Test } | undefined>;
  tests: AsyncState<{ test: Test } | undefined>;

  isDeploymentPreviewOpen: boolean;
  setIsDeploymentPreviewOpen: React.Dispatch<React.SetStateAction<boolean>>;

  releaseDetailAction: Action | null;
  setReleaseDetailAction: React.Dispatch<React.SetStateAction<Action | null>>;

  marketplaceLink: string | undefined;
  refreshMarketPlaceLink: () => void;
};

export const ReleaseDetailPageContext =
  React.createContext<ReleaseDetailPageContextProps>(
    {} as ReleaseDetailPageContextProps,
  );

interface Props {
  children?: React.ReactNode;
  release: ReleaseEntity;
  entity: Entity;
  systemType: SystemType;
  fetchRelease: () => Promise<void>;
  queryParamVersion: string | null;
  queryParamEnvironment: string | null;
}

const useMarketplaceLink = (
  environmentName: string,
  entity: Entity,
  deploymentUnitStatusState: GetProvisioningStatusResponse | undefined | null,
): {
  marketplaceLink?: string;
  refreshMarketPlaceLink: () => void;
} => {
  let marketplaceRoute =
    deploymentUnitStatusState?.provisioningDetails?.provisioningInfo
      ?.privateInfo?.marketplaceInfo?.href;

  const { data, refetch } = useQuery<{
    dataProductInstance: { id: number }[];
  }>(gql(GET_DATA_PRODUCT_INSTANCE_ID), {
    variables: {
      environmentName,
      dataProductExternalId: generateURNByKind(entity.metadata.name, 'dp'),
    },
    fetchPolicy: 'cache-and-network',
  });
  const idDpInstance = data?.dataProductInstance?.[0]?.id;

  marketplaceRoute = idDpInstance
    ? `/marketplace/search/${idDpInstance}`
    : undefined;

  return {
    marketplaceLink: marketplaceRoute,
    refreshMarketPlaceLink: refetch,
  };
};

export const ReleaseDetailPageContextProvider: React.FC<Props> = ({
  release,
  entity,
  systemType,
  fetchRelease,
  children,
  queryParamEnvironment,
  queryParamVersion,
}) => {
  const alertApi = useApi(customAlertApiRef);
  const panelCatalogApi = useApi(panelCatalogApiRef);
  const { environment, setEnvironment, environmentList } =
    useSelectorsContext();
  const [isDeployingAll, setIsDeployingAll] = useState<boolean>(false);
  const [isUndeployingAll, setIsUndeployingAll] = useState<boolean>(false);
  const [components, setComponents] = useState<Component[]>([]);
  const [filters, setFilters] = useState<ReleaseDetailFilters>({});
  const [latestProvisioningExpanded, setLatestProvisioningExpanded] =
    useState<boolean>(false);
  const [selectedStep, setSelectedStep] = useState<Dag>();
  const [selectedDeploy, setSelectedDeploy] = useState<Deploy>();
  const [isLogsDrawerOpen, setIsLogsDrawerOpen] = useState<boolean>(false);
  const [terminateMode, setTerminateMode] = useState<TerminationMode>();
  const [isValidationTestDrawerOpen, setIsValidationTestDrawerOpen] =
    useState<boolean>(false);
  const [
    validationTestResultDetailContent,
    setValidationTestResultDetailContent,
  ] = useState<JSX.Element>();
  const [isDescriptorDrawerOpen, setIsDescriptorDrawerOpen] =
    useState<boolean>(false);
  const [currentDescriptor, setCurrentDescriptor] = useState<string>('');
  const [testTaskId, setTestTaskId] = useState<string>();
  const [isDeploymentPreviewOpen, setIsDeploymentPreviewOpen] =
    useState<boolean>(false);
  const [releaseDetailAction, setReleaseDetailAction] = useState<Action | null>(
    null,
  );

  const projectName =
    (entity?.spec?.mesh as any)?.name ??
    release?.metadata?.projectName ??
    release?.metadata?.dataProductName;
  const [descriptorReleaseState, fetchReleaseDescriptor] =
    useAsyncFn(async () => {
      try {
        if (environment?.name && release) {
          return await panelCatalogApi.fetchReleasePreviewDescriptor(
            release.metadata.name,
            environment.name,
          );
        }
      } catch (error) {
        alertApi.post({ error, severity: 'error' });
        return null;
      }
      return null;
    }, [release, environment]);

  const [deploymentUnitStatusState, fetchDeploymentUnitStatus] =
    useAsyncFn(async () => {
      if (!environment?.name) return null;
      try {
        const urn = generateURNByKind(
          release.metadata.projectName ?? release.metadata.dataProductName,
          release.metadata.projectKind ?? 'System',
        );
        return await panelCatalogApi.getDeploymentUnitStatus(
          urn,
          environment?.name,
          true,
        );
      } catch (error) {
        alertApi.post({
          error,
          severity: 'error',
        });
      }
      return null;
    }, [environment]);

  const { marketplaceLink, refreshMarketPlaceLink } = useMarketplaceLink(
    environment.name,
    entity,
    deploymentUnitStatusState.value,
  );

  useEffect(() => {
    if (descriptorReleaseState.value && !queryParamVersion) {
      setComponents(getComponentsFromDescriptor(descriptorReleaseState.value));
      setCurrentDescriptor(descriptorReleaseState.value);
    }
  }, [descriptorReleaseState, queryParamVersion]);

  useEffect(() => {
    if (deploymentUnitStatusState.value) setTerminateMode(undefined);

    if (
      deploymentUnitStatusState.value &&
      deploymentUnitStatusState.value.provisioningDetails?.descriptor &&
      queryParamVersion
    ) {
      const stringifiedDescriptor = yaml.stringify(
        deploymentUnitStatusState.value.provisioningDetails?.descriptor,
      );
      setComponents(getComponentsFromDescriptor(stringifiedDescriptor));
      setCurrentDescriptor(stringifiedDescriptor);
    }
  }, [deploymentUnitStatusState, queryParamVersion]);

  useEffect(() => {
    if (queryParamEnvironment) {
      setEnvironment(
        environmentList.find(e => e.name === queryParamEnvironment)!,
      );
    }
  }, [environmentList, queryParamEnvironment, setEnvironment]);

  const [deploysState, fetchDeploys] = useAsyncFn(
    async (options?: { includeSnapshots?: boolean }) => {
      try {
        const deploys =
          await panelCatalogApi.getProvisioningPlansByEntityIdAndEnvironment(
            generateURNByKind(entity.metadata.name, entity.kind),
            environment.name,
            false,
            options?.includeSnapshots ?? true,
            {
              version: queryParamVersion
                ? buildReleaseVersion({ v: queryParamVersion })
                : buildReleaseVersion({ release }),
              operations: [TaskAction.PROVISION, TaskAction.UNPROVISION],
              offset: 0,
              limit: 1,
            },
          );

        return mapToDeploys(deploys.provisioningPlans, entity);
      } catch (error) {
        alertApi.post({ error, severity: 'error' });
        return [];
      }
    },
    [environment],
  );

  const [tests, fetchTests] = useAsyncFn(async () => {
    try {
      if (!testTaskId) return undefined;
      const response = await panelCatalogApi.getProvisioningPlan(testTaskId);
      const test = mapToTests([response])?.[0];
      return { test };
    } catch (error) {
      alertApi.post({ error, severity: 'error' });
      return undefined;
    }
  }, [testTaskId]);

  useEffect(() => {
    if (testTaskId) fetchTests();
  }, [testTaskId, fetchTests]);

  const terminate = useCallback(
    async (mode: TerminationMode) => {
      setTerminateMode(mode);

      const deploymentUnitId = generateURNByKind(
        release.metadata.projectName ?? release.metadata.dataProductName,
        release.metadata.projectKind ?? 'System',
      );

      if (deploymentUnitId) {
        const response = await panelCatalogApi.terminateProvisioning(
          deploymentUnitId,
          environment.name,
          mode,
        );
        return response;
      }
      return undefined;
    },
    [
      environment.name,
      panelCatalogApi,
      release.metadata.dataProductName,
      release.metadata.projectKind,
      release.metadata.projectName,
    ],
  );

  const deployAll = useCallback(async () => {
    setIsDeployingAll(true);
    try {
      const deployResponse = await panelCatalogApi.deployRelease(
        release.metadata.name,
        release.metadata.projectName ?? release.metadata.dataProductName,
        release.metadata.projectKind ?? 'system',
        environment.name,
      );

      if (deployResponse.errors) {
        throw new Error(JSON.stringify(deployResponse.errors));
      }

      fetchDeploys();
    } catch (error) {
      setIsDeployingAll(false);
      alertApi.post({
        error,
        severity: 'error',
      });
    }
  }, [panelCatalogApi, environment, release, fetchDeploys, alertApi]);

  const undeployAll = useCallback(async () => {
    setIsUndeployingAll(true);
    try {
      const deployResponse = await panelCatalogApi.undeployRelease(
        release.metadata.name,
        release.metadata.projectName ?? release.metadata.dataProductName,
        release.metadata.projectKind ?? 'system',

        environment.name,
      );

      if (deployResponse.errors) {
        throw new Error(JSON.stringify(deployResponse.errors));
      }

      fetchDeploys();
    } catch (error) {
      setIsUndeployingAll(false);
      alertApi.post({
        error,
        severity: 'error',
      });
    }
  }, [alertApi, environment, fetchDeploys, panelCatalogApi, release]);

  return (
    <ReleaseDetailPageContext.Provider
      value={useMemo(() => {
        const componentsStatus =
          deploymentUnitStatusState.value?.provisioningDetails
            ?.componentsStatus || [];

        const memoizedComponents = components.map(component => {
          const c = componentsStatus.find(
            status => status.componentId === component.id,
          );
          return {
            ...component,
            status: (c?.status ||
              DeploymentUnitStatus.NOT_DEPLOYED) as DeploymentUnitStatus,
            statusVersion: c?.descriptorVersion ?? '',
          };
        });

        return {
          release,
          projectName,
          entity,
          systemType,
          environment,
          fetchRelease,
          deploysState,
          fetchDeploys,
          fetchReleaseDescriptor,
          components: memoizedComponents,
          filters,
          changeFilters: <K extends keyof ReleaseDetailFilters>(
            key: K,
            filterValue: ReleaseDetailFilters[K],
          ) => {
            setFilters(f => ({ ...f, [key]: filterValue }));
          },
          resetFilters: () => {
            setFilters({});
          },
          latestProvisioningExpanded,
          setLatestProvisioningExpanded,
          deployAll,
          undeployAll,
          terminate,
          terminateMode,
          setTerminateMode,
          isDeployingAll,
          isUndeployingAll,
          setIsDeployingAll,
          setIsUndeployingAll,
          selectedStep,
          setSelectedStep,
          selectedDeploy,
          setSelectedDeploy,
          isLogsDrawerOpen,
          setIsLogsDrawerOpen,
          validationTestResultDetailContent,
          setValidationTestResultDetailContent,
          isValidationTestDrawerOpen,
          setIsValidationTestDrawerOpen,
          queryParamEnvironment,
          queryParamVersion,
          isDescriptorDrawerOpen,
          setIsDescriptorDrawerOpen,
          deploymentUnitStatusState,
          fetchDeploymentUnitStatus,
          currentDescriptor,
          testTaskId,
          setTestTaskId,
          fetchTests,
          tests,
          releaseDetailAction,
          setReleaseDetailAction,
          isDeploymentPreviewOpen,
          setIsDeploymentPreviewOpen,
          marketplaceLink,
          refreshMarketPlaceLink,
        };
      }, [
        deploymentUnitStatusState,
        components,
        release,
        projectName,
        entity,
        systemType,
        environment,
        fetchRelease,
        deploysState,
        fetchDeploys,
        fetchReleaseDescriptor,
        filters,
        latestProvisioningExpanded,
        deployAll,
        undeployAll,
        terminate,
        terminateMode,
        isDeployingAll,
        isUndeployingAll,
        selectedStep,
        selectedDeploy,
        isLogsDrawerOpen,
        validationTestResultDetailContent,
        isValidationTestDrawerOpen,
        queryParamEnvironment,
        queryParamVersion,
        isDescriptorDrawerOpen,
        fetchDeploymentUnitStatus,
        currentDescriptor,
        testTaskId,
        fetchTests,
        tests,
        releaseDetailAction,
        isDeploymentPreviewOpen,
        marketplaceLink,
        refreshMarketPlaceLink,
      ])}
    >
      {children}
    </ReleaseDetailPageContext.Provider>
  );
};
