import { Entity } from '@backstage/catalog-model';
import { WitboostEntity, WitboostSystem } from './model';

/**
 * Type used to combine Data Product/Components info and their environments
 */
export type DataProductAndEnvironmentConfiguration = {
  dataProduct: WitboostSystem;
  configuration: any;
  components: { component: WitboostEntity; configuration: any }[];
};

export type Release = {
  date: string;
  link: string;
  environment: string;
};

export type BasicURN = {
  urn: string;
  dmb: string;
  kind: string;
};

export type URN = DomainURN &
  GroupURN &
  UserURN &
  ResourceURN &
  DPURN &
  ComponentURN &
  ReleaseURN &
  SubcomponentURN &
  TaxonomyURN;

export type TaxonomyURN = BasicURN & {
  name: string;
};

export type DomainURN = BasicURN & {
  domain: string;
};

export type GroupURN = DomainURN & {
  name: string;
};

export type UserURN = DomainURN & {
  name: string;
};

export type ResourceURN = BasicURN & {
  name: string;
  version: string | undefined;
  domain?: string;
};

export type DPURN = DomainURN & {
  name: string;
  version: string;
};

export type ComponentURN = DPURN & {
  component: string;
};

export type SubcomponentURN = ComponentURN & {
  subcomponent: string;
};

export type ReleaseURN = DPURN & {
  releaseVersion: string;
};

export type UseCaseTemplateURN = BasicURN & {
  name: string;
  version: string;
};

export enum RELATION_DEPENDENCY {
  READS_FROM = 'wb-readsFrom',
  ISREADBY = 'wb-isReadBy',
}

export enum RELATION_SYSTEM_OWNERSHIP {
  SYSTEM_OWNER_OF = 'wb-systemOwnerOf',
  SYSTEM_OWNED_BY = 'wb-systemOwnedBy',
}

export enum RELATION_SYSTEM_DEVELOPER {
  DEVELOPER_OF = 'wb-developerOf',
  DEVELOPED_BY = 'wb-developedBy',
}

export const RELATION_HAS_MAIN_TEMPLATE = 'wb-hasMainTemplate';
export const RELATION_MAIN_TEMPLATE_OF = 'wb-mainTemplateOf';
export const RELATION_EDITABLE_BY = 'wb-editableBy';
export const RELATION_CAN_EDIT = 'wb-canEdit';

export type CatalogEntityRelation = {
  type: string;
  source: Entity;
  target: Entity;
};

export type RepositoryFile = {
  repoUrl: string;
  branch: string;
  /**
   * e.g. dir1/dir2/file-name.ext
   */
  filePath: string;
  /**
   * File content
   */
  content: string;
  /**
   * Commit hash (commit ID) associated with the version of the file's content
   */
  commit: string;
};

export type CatalogInfoFile = RepositoryFile & {
  parsedCatalogInfo: any;
} & { [key: string]: any };

export type EntityAndBranch = {
  entityRef: string;
  branch: string;
  commitUid?: string;
};
